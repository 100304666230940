body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}
.loader-centered {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 999;
}
.loader-centered svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.content-panel {
  flex: 1;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
/*custom css*/
body,
p,
button,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
a,
td,
li {
  /* font-family: Verdana !important; */
  font-family: "Open Sans", sans-serif !important;
}
section h3 {
  color: #23272c;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}
section h2 {
  /* border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey; */
  padding: 10px 0;
  color: #00b050;
  font-weight: 600;
  font-size: 26px;
}
section ul li {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 12px;
  color: #23272c;
  line-height: 22px;
}
section button {
  font-weight: 600 !important;
  padding: 12px 20px !important;
  width: 100%;
}
.section {
  /* box-shadow: 0px 0px 5px #ccc; */
  padding: 30px 20px;
  margin-top: 35px;
  margin-bottom: 35px;
}
section h4 {
  color: #23272c;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
}
.menu-active {
  background-color: #00b050 !important;
  color: #fff !important;
  outline-color: #00b050 !important;
}
.sub-menu-active {
  background-color: rgb(0, 176, 80) !important;
  color: #fff !important;
  outline-color: rgb(0, 176, 80) !important;
}
.table thead th {
  border-top: 1px solid #dfe5e9;
  border-bottom: 1px solid #dfe5e9;
  color: #667585;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
}
.box {
  width: 100%;
  text-align: center;
  /* box-shadow: 0px 0px 5px #ccc; */
  padding: 25px 0;
  margin-bottom: 30px;
  min-height: 120px;
  height: 145px;
  border-radius: 5px;
}
.section ul li {
  padding-bottom: 20px;
}
.box p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 12px;
}
.box h5 {
  margin-bottom: 0;
  word-break: break-all;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #667585;
}
.inner-box {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  width: 100%;
  transform: translate(-50%, -50%);
}
/* .menu-active::before {
  content: "";
  position: absolute;
  top: 49px;
  left: 50%;
  background: lightgrey;
  width: 1px;
  height: 12px;
  display: block;
} */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}
@-webkit-keyframes animatebottom {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
@keyframes animatebottom {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
.animate-box-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 3s;
}
@-webkit-keyframes animatebottom {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
@keyframes animatebottom {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
/* .sub-row {
  position: relative;
}
.sub-row::before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: lightgrey;
  top: -13px;
  position: absolute;
  z-index: 99;
  margin: 0 auto;
  left: 0;
}
.sub-row button::before {
  position: absolute;
  content: "";
  background: lightgrey;
  left: 50%;
  width: 1px;
  height: 12px;
  display: block;
  top: -13px;
} */

/*.alert-box-page {
  box-shadow: 0px 0px 5px #ccc;
  text-align: center;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  width: 500px;
  margin: 0 auto;
  height: 300px;
  align-self: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 10px);
}*/
.alert-box-page {
  box-shadow: 0px 0px 5px #ccc;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 500px;
  margin: 0 auto;
  align-self: center;
  position: relative;
  max-width: 100%;
  height: 200px;
  margin-top: 45px;
}
.w-20 {
  width: 20%;
}
.w-80 {
  width: 80%;
}
div#chart {
  margin-top: 30px;
}
div#chart1 {
  margin-top: 0px;
}
.about-hoaScore {
  font-size: 20px;
  color: #000;
}
.apexcharts-datalabel-value {
  font-size: 42px !important;
  font-weight: 600 !important;
}
/*header and footer css*/
header {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 99;
}
.social-icons-list a {
  transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
}

.social-icons-list a:hover {
  transform: translateY(-5px);
  transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
}
.navbar-nav .nav-link {
  color: #3a3a3a;
  font-weight: 600;
  font-size: 16px;
  padding: 0 1rem !important;
  font-family: "Open Sans", sans-serif !important;
  position: relative;
  
}

.navbar-brand img {
  width: 200px;
  height: auto;
}

.navbar-nav li.nav-item.active .nav-link,
.navbar-nav .nav-link:hover {
  color: rgb(0 176 80);
}
.head-btn {
  background: #00b050;
  border-radius: 5px;
  color: #fff;
  padding: 6px 20px;
 
  font-weight: 600;
  margin-left: 14px;
  font-family: "Open Sans", sans-serif !important;
}
/* nav.navbar {
  box-shadow: 0px 0px 5px #ccc;
} */
footer {
  background:#00a34a;
  color: #fff;
  padding-top: 45px;
  padding-bottom: 30px;
  margin-top: 50px;
}
.copy-right-section {
  background:#00a34a;
}
footer h3 {
  color: #91fa95;
  margin-bottom: 25px;
  font-size: 25px;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600;
  font-size: 18px;
}
footer p,
.foot-nav-links li a,
.copy-right-section p {
  margin-bottom: 0;
  line-height: 24px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif !important;
  color: #ffffff;
  font-size: 14px;
}
.click-btn {
  background: #0274be;
  color: #fff;
  text-transform: uppercase;
  background-color: #0274be;
  border-radius: 2px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600;
}
.click-btn:hover {
  background: #000;
}
.social-icons-list a {
  color: #fff;
  width: 34px;
  height: 34px;
  display: block;
  text-align: center;
  border-radius: 100%;
  line-height: 34px;
  font-size: 18px;
}
.fb {
  background: #3b5998;
}
.twiter {
  background: #1da1f2;
  margin-left: 12px;
}
.copy-right-section {
  background:#00a34a;
  border-top: 0px solid #7a7a7a;
  padding-top: 1.5em;
  padding-bottom: 1em;
}
button:focus {
  outline: 0px;
}
/*end header and footer*/
/*Responsive css*/
@media only screen and (max-width: 767px) {
  footer {
    margin-top: 0;
  }
  body section .Hoa-scrore-txt {
    margin: 30px auto 0px !important;
    font-size: 16px !important;
  }
  .semi-circle-responsive {
    width: 100% !important;
    /* height: 150px !important; */
  }
  body section .main-row .MuiButton-outlinedPrimary {
    padding: 15px 10px !important;
  }
  .main-row .white-section .MuiButton-outlinedPrimary {
    border-radius: 5px;
    padding: 15px 10px !important;
    margin-left: 0px;
    border: 1px solid #ebebeb;
  }
  .main-row .white-section {
    margin-bottom: 35px;
  }
  section h6 {
    font-size: 15px !important;
    line-height: 24px !important;
  }
  .head-btn {
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .navbar-toggler {
    width: 20%;
    line-height: 33px;
  }
  .navbar-brand {
    margin-right: 0;
    float: left;
    width: 80%;
  }
  .navbar-brand img {
    width: 220px; 
    height: auto;
    background-color:rgba(0, 0, 0, 0);
  }
  footer h3 {
    margin-bottom: 12px;
  }
  .circle-section {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .circle-section div#chart {
    margin-bottom: 20px;
  }
  .circle-section div#chart1 {
    margin-bottom: 20px;
  }
  .chart-bottom-text p {
    margin-bottom: 15px !important;
  }
  div#chart {
    margin-top: 30px;
  }
  /* .box p {
    font-size: 16px;
  } */
  /* .box h5 {
    font-size: 20px;
  } */
  section h2 {
    margin-top: 20px;
    font-size: 20px !important;
  }
  section button {
    margin-bottom: 20px !important;
  }
  .sub-row::before {
    height: 100%;
    width: 1px;
    top: -20px;
  }
  .sub-row button::before {
    left: -33px;
    width: 32px;
    height: 2px;
    top: 50%;
  }
  .sub-row button {
    margin-left: 18px;
  }
  .menu-active::before {
    display: none;
  }
  section h4 {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: normal;
  }
  section h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  footer h3 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  header .navbar-nav .nav-link {
    font-size: 14px;
    padding: 7px 8px !important;
    top: 2px;
  }
  .head-btn {
    margin-left: 9px;
    font-size: 15px;
  }
  div#chart {
    margin-top: 32px;
  }
  /* .box p {
    font-size: 18px;
  }
  .box h5 {
    font-size: 20px;
  } */
  section h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 1199px) and (min-width: 1024px) {
  .navbar-nav .nav-link {
    font-size: 17px;
  }
}
@media only screen and (max-width: 1023px) {
  .copy-right-section {
    text-align: center;
  }
  .navbar-brand img {
    width: 200px;
    height: auto;
  }
  .navbar-nav .nav-link {
    padding: 7px 10px !important;
  }
}
/* .MuiButton-label span img {
  width: 18px;
  position: relative;
  top: -3px;
   margin-right: 5px;
} */
table tbody th {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #23272c;
}
section h6 {
  color: #23272c;
  font-size: 16px;
  line-height: 42px;
}
section .main-row .MuiButton-outlinedPrimary {
  color: #23272c;
  border: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: none;
  /* box-shadow: 15px 15px 30px rgba(2, 107, 50, 0.3); */
  border-radius: 15px;
  padding: 30px 10px !important;
  background-color: #fff;
}
section .main-row .menu-active.MuiButton-outlinedPrimary {
  box-shadow: 10px 10px 30px rgba(2, 107, 50, 0.3);
}
section .main-row .MuiButton-outlinedPrimary span {
  display: block;
}
.main-row .MuiButton-label span img {
  margin-bottom: 8px;
  height: 40px;
}
.sub-row .MuiButton-outlinedPrimary {
  color: #667585;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 5px;
  text-transform: none;
  padding: 16px 10px !important;
}
.sub-row .MuiButton-outlinedPrimary .MuiButton-label span img {
  display: none;
}
.sub-row .MuiButton-outlinedPrimary:hover {
  border: 1px solid #00b050;
  background-color: rgba(63, 81, 181, 0.04);
}
.section ul li:last-child {
  padding-bottom: 0;
}
section .Hoa-scrore-txt {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 42px;
  margin: 35px auto 0px;
  width: 90%;
  max-width: 100%;
}
.chart-bottom-text p {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.circle-section,
.fees-projection {
  border-radius: 15px;
  border: 1px solid #ebebeb;
  padding-top: 45px;
}
.icon-box-text img {
  /* width: 30px; */
  margin-bottom: 10px;
}
.icon-box-text p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0px;
  color: #23272c;
}
@media only screen and (max-width: 1023px) {
  section .Hoa-scrore-txt {
    width: 100%;
    margin: 45px auto 30px;
    font-size: 18px !important;
  }
}
.white-section {
  border-radius: 15px;
  padding: 25px;
}
.section tbody {
  border-bottom: 1px solid #dfe5e9;
}
@media only screen and (max-width: 575px) {
  .icon-box-text {
    margin-bottom: 40px;
  }
  .semi-circle-responsive {
    width: auto;
    height: auto;
  }
}
.semi-circle-responsive {
  width: 400px;
  height: 240px;
  margin: 0 auto;
}
.fincials-icon img {
  width: 40px;
}
.bylwas-icon img {
  width: 32px;
}
.operation-icon img {
  width: 42px;
}
.finacing-icons img {
  width: 39px;
}
